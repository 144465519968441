import DatumUhrzeit from "../commons/DatumUhrzeit.js";
import Misc from "../commons/misc.js";
export default class Kasse {
    anfangsbestandEUR = 0;
    ausgabeBankEUR = 0;
    ausgabeCateringEUR = 0;
    ausgabeHelferEUR = 0;
    ausgabeSonstiges1EUR = 0;
    ausgabeSonstiges2EUR = 0;
    ausgabeSonstiges3EUR = 0;
    einnahmeBankEUR = 0;
    einnahmeSonstiges1EUR = 0;
    einnahmeTicketsEUR = 0;
    einnahmeSonstiges2EUR = 0;
    ausgabeSonstiges1Text = "";
    ausgabeSonstiges2Text = "";
    ausgabeSonstiges3Text = "";
    einnahmeSonstiges1Text = "";
    einnahmeSonstiges2Text = "";
    anzahlBesucherAK = 0;
    kassenfreigabe = "";
    kassenfreigabeAm;
    einnahmenReservix = 0; // darf nicht in kassenberechnung
    startinhalt = {
        "10": undefined,
        "20": undefined,
        "50": undefined,
        "100": undefined,
        "200": undefined,
        "500": undefined,
        "1000": undefined,
        "2000": undefined,
        "5000": undefined,
        "10000": undefined,
    };
    endinhalt = {
        "10": undefined,
        "20": undefined,
        "50": undefined,
        "100": undefined,
        "200": undefined,
        "500": undefined,
        "1000": undefined,
        "2000": undefined,
        "5000": undefined,
        "10000": undefined,
    };
    endbestandGezaehltEUR = 0;
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
    toJSON() {
        return Object.assign({}, this);
    }
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
    constructor(object) {
        if (object && Object.keys(object).length !== 0) {
            const ak = object.anzahlBesucherAK;
            Object.assign(this, object, {
                kassenfreigabeAm: Misc.stringOrDateToDate(object.kassenfreigabeAm),
                anzahlBesucherAK: typeof ak === "string" ? parseInt(ak) : isNaN(ak) ? 0 : ak,
                startinhalt: {
                    "10": object.startinhalt?.["10"],
                    "20": object.startinhalt?.["20"],
                    "50": object.startinhalt?.["50"],
                    "100": object.startinhalt?.["100"],
                    "200": object.startinhalt?.["200"],
                    "500": object.startinhalt?.["500"],
                    "1000": object.startinhalt?.["1000"],
                    "2000": object.startinhalt?.["2000"],
                    "5000": object.startinhalt?.["5000"],
                    "10000": object.startinhalt?.["10000"],
                },
                endinhalt: {
                    "10": object.endinhalt?.["10"],
                    "20": object.endinhalt?.["20"],
                    "50": object.endinhalt?.["50"],
                    "100": object.endinhalt?.["100"],
                    "200": object.endinhalt?.["200"],
                    "500": object.endinhalt?.["500"],
                    "1000": object.endinhalt?.["1000"],
                    "2000": object.endinhalt?.["2000"],
                    "5000": object.endinhalt?.["5000"],
                    "10000": object.endinhalt?.["10000"],
                },
            });
        }
    }
    get ausgabenOhneGage() {
        return (this.ausgabeCateringEUR + this.ausgabeHelferEUR + this.ausgabeSonstiges1EUR + this.ausgabeSonstiges2EUR + this.ausgabeSonstiges3EUR);
    }
    get ausgabenTotalEUR() {
        return this.ausgabeBankEUR + this.ausgabenOhneGage;
    }
    get einnahmeOhneBankUndTickets() {
        return this.einnahmeSonstiges1EUR + this.einnahmeSonstiges2EUR;
    }
    get einnahmeTotalEUR() {
        return this.einnahmeBankEUR + this.einnahmeOhneBankUndTickets + this.einnahmeTicketsEUR;
    }
    get endbestandEUR() {
        return this.anfangsbestandEUR + this.einnahmeTotalEUR - this.ausgabenTotalEUR;
    }
    set endbestandEUR(betrag) {
        // no op
    }
    // FREIGABE
    get istFreigegeben() {
        return !!this.kassenfreigabe;
    }
    get freigabeDisplayDatum() {
        return this.kassenfreigabeAm ? DatumUhrzeit.forJSDate(this.kassenfreigabeAm).tagMonatJahrLang : "";
    }
}

import { SUPERUSERS } from "./user.js";
import misc from "../commons/misc.js";
import flatten from "lodash/flatten.js";
import uniq from "lodash/uniq.js";
export class Mailingliste {
    name;
    users;
    constructor(name, usersInListe) {
        this.name = name;
        this.users = usersInListe.map((u) => u.id);
    }
}
class Users {
    users = [];
    constructor(users) {
        this.users = users;
    }
    filterReceivers(groupsFromBody, userFromBody, listenFromBody) {
        let result = [];
        if (groupsFromBody && groupsFromBody.length > 0) {
            if (misc.toArray(groupsFromBody).includes("alle")) {
                return this.users;
            }
            result = result.concat(uniq(flatten(misc
                .toArray(groupsFromBody)
                .concat(SUPERUSERS)
                .map((group) => this.users.filter((user) => user.gruppen.includes(group))))));
        }
        if (listenFromBody && listenFromBody.length > 0) {
            result = result.concat(uniq(flatten(misc.toArray(listenFromBody).map((liste) => this.getUsersInListe(liste)))));
        }
        return uniq(result.concat(this.users.filter((user) => (userFromBody || []).includes(user.id))));
    }
    extractListen() {
        return uniq(flatten(this.users.map((u) => u.mailinglisten)));
    }
    getUsersInListe(listenname) {
        return this.users.filter((u) => u.mailinglisten.includes(listenname));
    }
    getUsersInGruppenExact(gruppennamen) {
        return this.users.filter((u) => {
            const bools = gruppennamen?.map((name) => {
                return u.gruppen.includes(name);
            });
            return bools?.reduce((curr, next) => curr || next, false);
        });
    }
    getUsersKann(kann) {
        return this.users.filter((u) => u.kann(kann));
    }
    getUsersKannOneOf(kannMultiple) {
        return this.users.filter((u) => {
            const bools = kannMultiple?.map((kann) => u.kann(kann));
            return bools?.reduce((curr, next) => curr || next, false);
        });
    }
    get mailinglisten() {
        return this.extractListen().map((name) => new Mailingliste(name, this.getUsersInListe(name)));
    }
}
export default Users;

import misc from "../commons/misc.js";
import DatumUhrzeit from "../commons/DatumUhrzeit.js";
function eventsToObject(contents, jahrMonat) {
    if (!contents || !jahrMonat) {
        return [];
    }
    const jmArray = jahrMonat.split("/");
    function lineToObject(line) {
        const datum = DatumUhrzeit.forYYYYMM(jmArray[0] + jmArray[1]).minus({
            monate: 2,
        });
        function padLeftWithZero(aNumberString) {
            return (aNumberString.length === 1 ? "0" : "") + aNumberString;
        }
        function toDate(dayMonthString, stunde = "00:00") {
            const dayMonth = dayMonthString ? dayMonthString.split(".") : [];
            if (dayMonth.length < 2) {
                return null;
            }
            if (!misc.isNumber(dayMonth[0]) || !misc.isNumber(dayMonth[1])) {
                return null;
            }
            const day = padLeftWithZero(dayMonth[0]);
            const month = padLeftWithZero(dayMonth[1]);
            const dateString = `${day}.${month}.${datum.jahr}`;
            return DatumUhrzeit.forGermanStringOrNow(dateString, stunde).toJSDate;
        }
        function dates(element) {
            if (element.trim()) {
                const fromAndUntil = misc.compact(element.split("-").map((each) => each.trim()));
                const from = toDate(fromAndUntil[0]);
                const until = toDate(fromAndUntil[1] || fromAndUntil[0], "22:00"); // 22 hours
                if (from && until) {
                    return [from.toISOString(), until.toISOString()];
                }
            }
            return null;
        }
        const elements = line.split("|");
        if (elements.length < 4) {
            return;
        }
        const was = elements[0];
        const wer = elements[1];
        const farbe = elements[2];
        const fromUntil = dates(elements[3]);
        const email = elements[4] || "";
        const emailOffset = misc.isNumber(elements[5]) ? Number.parseInt(elements[5]) : 7;
        if (was && fromUntil) {
            if (!email) {
                return {
                    start: fromUntil[0],
                    end: fromUntil[1],
                    title: was.trim() + " (" + wer.trim() + ")",
                    farbe: farbe.trim(),
                };
            }
            return {
                start: fromUntil[0],
                end: fromUntil[1],
                title: was.trim() + " (" + wer.trim() + ")",
                farbe: farbe.trim(),
                email: email.trim(),
                emailOffset: emailOffset,
                was: was.trim(),
                wer: wer.trim(),
            };
        }
    }
    const lines = contents.split(/[\n\r]/);
    return misc.compact(lines.map(lineToObject));
}
export class EmailEvent {
    event;
    constructor(event) {
        this.event = event;
    }
    datumUhrzeitToSend() {
        return this.start().minus({ tage: this.event.emailOffset });
    }
    shouldSendOn(datumUhrzeit) {
        return Math.abs(this.datumUhrzeitToSend().differenzInTagen(datumUhrzeit)) === 0;
    }
    start() {
        return DatumUhrzeit.forISOString(this.event.start);
    }
    email() {
        return this.event.email;
    }
    body() {
        return `${this.event.wer},

hier eine automatische Erinnerungsmail:
${this.event.was}

Vielen Dank für Deine Arbeit und Unterstützung,
Damit alles reibungslos klappt, sollte dies bis zum ${this.start().tagMonatJahrLang} erledigt sein.

Danke & keep swingin'`;
    }
}
export default class Kalender {
    id;
    text = `Was | Wer | Farbe | Wann | Email | Tage vorher
--- | --- | --- | --- | --- | ---
`;
    constructor(object) {
        if (object && object.id && object.id.split("/").length === 2) {
            const splits = object.id.split("/");
            if (misc.isNumber(splits[0]) && misc.isNumber(splits[1])) {
                this.id = object.id;
                if (object.text !== "") {
                    this.text = object.text;
                }
                return;
            }
        }
        this.id = "2018/01";
    }
    year() {
        return this.id && this.id.split("/")[0];
    }
    asEvents() {
        return eventsToObject(this.text, this.id);
    }
    eventsToSend(aDatumUhrzeit) {
        const events = this.asEvents()
            .filter((e) => !!e.email)
            .map((e) => new EmailEvent(e));
        return events.filter((e) => e.shouldSendOn(aDatumUhrzeit));
    }
}

import misc from "../commons/misc.js";
import renderer from "../commons/renderer.js";
export default class Vertrag {
    art = "Jazzclub";
    sprache = "Deutsch";
    datei = [];
    zusatzvereinbarungen;
    static arten() {
        return ["Jazzclub", "Agentur/Künstler", "JazzClassix"];
    }
    static sprachen() {
        return ["Deutsch", "Englisch", "Regional"];
    }
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
    toJSON() {
        return Object.assign({}, this);
    }
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
    constructor(object) {
        if (object && Object.keys(object).length !== 0) {
            Object.assign(this, object, {
                datei: misc.toArray(object.datei),
            });
        }
    }
    get zusatzvereinbarungenHtml() {
        return renderer.render(this.zusatzvereinbarungen ?? "");
    }
    updateDatei(datei) {
        const imagePushed = misc.pushImage(this.datei, datei);
        if (imagePushed) {
            this.datei = imagePushed;
            return true;
        }
        return false;
    }
    removeDatei(datei) {
        this.datei = misc.dropImage(this.datei, datei);
    }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import compact from "lodash/compact.js";
import DatumUhrzeit from "./DatumUhrzeit.js";
function isNumber(aString) {
    const number = Number.parseInt(aString);
    return !!number || number === 0;
}
function stringOrDateToDate(object) {
    if (!object) {
        return undefined;
    }
    return typeof object === "string" ? DatumUhrzeit.forISOString(object).toJSDate : object;
}
function toObject(Constructor, jsobject) {
    if (jsobject && Object.keys(jsobject).length > 0) {
        delete jsobject._csrf;
        return new Constructor(jsobject);
    }
    return null;
}
function toObjectList(Constructor, jsobjects) {
    return (jsobjects || []).map((each) => toObject(Constructor, each)).filter((each) => each !== null);
}
function toArray(elem) {
    if (!elem) {
        return [];
    }
    if (elem instanceof Array) {
        return elem;
    }
    if (typeof elem === "string") {
        return elem.split(",");
    }
    return [elem];
}
function pushImage(images, image) {
    let result;
    if (typeof images === "string") {
        result = [images];
    }
    else {
        result = images;
    }
    if (result.indexOf(image) === -1) {
        result.push(image);
        return result;
    }
    return result;
}
function dropImage(images, image) {
    if (typeof images === "string") {
        return [];
    }
    else {
        return images.filter((each) => each !== image);
    }
}
function normalizeString(input) {
    return input
        .replace(/[äÄàáÀÁâÂ]/gi, "a")
        .replace(/[èéÈÉêÊ]/gi, "e")
        .replace(/[ìíÌÍîÎ]/gi, "i")
        .replace(/[öÖòóÒÓôÔ]/gi, "o")
        .replace(/[üÜùúÙÚûÛ]/gi, "u")
        .replace(/ß/g, "s")
        .trim()
        .replace(/\s/g, "_")
        .replace(/\//g, "_")
        .replace(/[^a-zA-Z0-9\- _]/g, "")
        .toLowerCase();
}
export default { compact, isNumber, normalizeString, toObject, toObjectList, stringOrDateToDate, dropImage, pushImage, toArray };

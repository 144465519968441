import { colorDefault } from "../optionen/optionValues.js";
export default class Kopf {
    beschreibung = "";
    eventTyp = "";
    eventTypRich;
    flaeche = 100;
    kooperation = "";
    ort = "Jazzclub";
    titel = "";
    pressename = "Jazzclub Karlsruhe";
    presseIn = "im Jazzclub Karlsruhe";
    genre = "";
    confirmed = false;
    rechnungAnKooperation = false;
    abgesagt = false;
    fotografBestellen = false;
    kannAufHomePage = false;
    kannInSocialMedia = false;
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
    toJSON() {
        return Object.assign({}, this);
    }
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
    constructor(object) {
        if (object && Object.keys(object).length !== 0) {
            Object.assign(this, object);
        }
    }
    get isKooperation() {
        return !!this.kooperation && this.kooperation !== "_";
    }
    get rechnungAnKooperationspartner() {
        return !this.rechnungAnKooperation ? this.isKooperation : this.rechnungAnKooperation;
    }
    get presseInEcht() {
        return this.presseIn || this.pressename || this.ort;
    }
    get titelMitPrefix() {
        return `${this.abgesagt ? "Abgesagt: " : ""}${this.titel}`;
    }
    get kooperationspartnerText() {
        return this.isKooperation ? this.kooperation : "";
    }
    get color() {
        return this.eventTypRich?.color ?? colorDefault;
    }
}

import misc from "../commons/misc.js";
import Misc from "../commons/misc.js";
export default class Artist {
    bandname = "";
    name = [];
    numMusiker = 1;
    numCrew = 0;
    isBawue = false;
    isAusland = false;
    brauchtHotel = false;
    getInForMasterDate;
    bandTransport;
    toJSON() {
        return Object.assign({}, this);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(object) {
        if (object && Object.keys(object).length !== 0) {
            const getIn = Misc.stringOrDateToDate(object.getInForMasterDate);
            Object.assign(this, object, {
                name: misc.toArray(object.name), // legacy, was text before
                getInForMasterDate: getIn,
                bandTransport: object.bandTransport,
            });
        }
    }
}

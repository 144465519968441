import Termin from "./termin.js";
import Misc from "../commons/misc.js";
export class Ical {
    name = "";
    url = "";
    typ = "Sonstiges";
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(object) {
        if (object) {
            Object.assign(this, object);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJSON() {
        return Object.assign({}, this);
    }
    get color() {
        return Termin.colorForType(this.typ);
    }
}
export class KalenderEvents {
    id = "";
    content = "";
    updatedAt = new Date();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(object) {
        if (object) {
            this.id = object.id;
            this.content = object.content;
            this.updatedAt = Misc.stringOrDateToDate(object.updatedAt);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJSON() {
        return Object.assign({}, this);
    }
}
export default class FerienIcals {
    id = "ferienIcals";
    icals = [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(object) {
        if (object && object.icals) {
            this.icals = (object.icals || []).map((each) => new Ical(each));
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    toJSON() {
        return Object.assign({}, this, {
            icals: this.icals.map((i) => i.toJSON()),
        });
    }
}

export default class Message {
    subject;
    markdown;
    senderNameField;
    senderAddressField;
    replyTo;
    to;
    bcc;
    pdfBufferAndName;
    attachments;
    /* eslint-disable-next-line  @typescript-eslint/no-explicit-any*/
    static fromJSON(json) {
        const message = new Message({ subject: json.subject, markdown: json.markdown });
        message.bcc = json.bcc;
        message.senderNameField = json.senderNameField;
        message.senderAddressField = json.senderAddressField;
        message.replyTo = json.replyTo;
        return message;
    }
    constructor(subjectWithText, optionalSenderName, optionalSenderAddress) {
        if (subjectWithText) {
            this.subject = subjectWithText.subject;
            this.markdown = subjectWithText.markdown;
            this.senderNameField = optionalSenderName;
            this.replyTo = optionalSenderAddress;
        }
        return this;
    }
    senderName(confSenderName) {
        return this.senderNameField ? `${this.senderNameField} via backoffice.jazzclub.de` : confSenderName;
    }
    senderAddress(confSenderAddress) {
        return this.senderAddressField || confSenderAddress;
    }
    static formatEMailAddress(name, email) {
        const names = name.split(",");
        const emails = email.split(",");
        const result = names.map((name, index) => {
            return `"${name}" <${emails[index]}>`;
        });
        return result.join(",");
    }
    setTo(toAddresses) {
        if (toAddresses === undefined) {
            return;
        }
        if (typeof toAddresses === "string") {
            this.to = toAddresses;
        }
        else {
            this.to = (toAddresses || []).join(",");
        }
    }
    setBcc(toAddresses) {
        if (typeof toAddresses === "string") {
            this.bcc = toAddresses;
        }
        else {
            this.bcc = toAddresses.join(",");
        }
    }
}
